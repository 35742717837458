<template>
  <v-container
    fluid
    class="d-flex flex-column full-height pt-4"
  >
    <div class="bg" />

    <div
      v-hotkey="{ 'esc': () => showPasswordLost = false }"
      style="display: none;"
    />

    <v-row
      style="transform: translateZ(0);"
      class="flex-grow-1 mb-5"
    >
      <v-col>
        <!-- logo -->
        <v-img
          src="/favicons/favicon-96x96.png"
          max-width="48"
          class="mx-auto"
        />

        <!-- Mestamaster -->
        <div
          class="text-center white--text font-weight-bold display-1"
        >
          mestamaster
        </div>
        <!-- Sign in -->
        <div
          v-translate
          translate-context="Page content (login page)"
          class="body-2 font-weight-bold text-uppercase
                  text-center mt-1 mb-4 primary--text text--lighten-5"
        >
          Sign in
        </div>

        <!-- menu + card -->
        <v-menu
          v-model="showPasswordLost"
          transition="slide-x-transition"
          content-class="elevation-0"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-form
              ref="form"
              v-model="formValid"
              @submit.prevent="submit"
            >
              <v-card
                max-width="400px"
                class="mx-auto elevation-6"
              >
                <v-card-text
                  class="pb-0 pt-6"
                >
                  <v-text-field
                    v-model="email"
                    autofocus
                    :label="emailPlaceholder"
                    outlined
                    validate-on-blur
                    autocomplete="email"
                    type="email"
                    :rules="[validators.required, validators.email]"
                  />

                  <v-text-field
                    v-model="password"
                    :label="passwordPlaceholder"
                    outlined
                    validate-on-blur
                    autocomplete="current-password"
                    :rules="[validators.required]"
                    :type="showPassword ? 'text' : 'password'"
                    :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                    @click:append="showPassword = !showPassword"
                  />
                </v-card-text>

                <v-card-actions
                  class="px-4"
                >
                  <div
                    class="full-width"
                  >
                    <v-btn
                      color="primary"
                      block
                      large
                      height="54px"
                      type="submit"
                      :loading="$wait.is('logging in')"
                      :disabled="$wait.is('logging in')"
                    >
                      Let's go!
                    </v-btn>

                    <v-btn
                      ref="passwordLost"
                      block
                      large
                      text
                      class="mt-4 mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span
                        v-translate
                        translate-context="Page content (login page)"
                      >
                        Password lost?
                      </span>
                    </v-btn>

                    <v-expansion-panels class="mt-2 mb-2">
                      <v-expansion-panel>
                        <v-expansion-panel-header
                          class="caption--text text--darken-2"
                        >
                          <span
                            v-translate
                            translate-context="Page content (login page)"
                          >
                            Need some help?
                          </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <m-contact-details />
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                  </v-expansion-panels>

                  </div>
                </v-card-actions>
              </v-card>
            </v-form>
          </template>

          <m-forgot-password
            max-width="400px"
            height="386px"
            class="mx-auto"
            :email="email"
            :show="showPasswordLost"
            :on-close="() => showPasswordLost = false"
          />
        </v-menu>
      </v-col>
    </v-row>

    <v-footer app bottom fixed
      class="text-center d-block caption"
    >
      {{ new Date().getFullYear() }} &copy; Mestamaster Oy
    </v-footer>
  </v-container>
</template>

<script>
  import { mapWaitingActions } from 'vue-wait';
  import getValidators from '@/mixins/validators';
  import MForgotPassword from '@/components/MForgotPassword';

  export default {
    components: {
      MForgotPassword,
      MContactDetails: () => import('@/components/MContactDetails'),
    },

    props: {
      redirect: {
        type: String,
        default: null,
      },

      token: {
        type: String,
        default: null,
      },
    },

    data: () => ({
      email: '',
      password: '',
      showPassword: false,
      formValid: false,
      showPasswordLost: false,
      ...getValidators('required', 'email'),
    }),

    computed: {
      emailPlaceholder() {
        return this.$pgettext('Text input placeholder', 'Email');
      },

      passwordPlaceholder() {
        return this.$pgettext('Text input placeholder', 'Password');
      },
    },

    created() {
      if (this.token) this.tryAutoLogin();
    },

    methods: {
      ...mapWaitingActions('currentUser', {
        login: 'logging in',
        autoLogin: 'logging in',
      }),

      async tryAutoLogin() {
        const success = await this.autoLogin({ token: this.token });
        if (!success) return;
        await this.loginOk();
      },

      async submit() {
        await this.$refs.form.validate();

        if (!this.formValid) return;

        const success = await this.login({
          session: {
            email: this.email,
            password: this.password,
          },
        });

        if (!success) return;

        await this.loginOk();
      },

      async loginOk() {
        const to = this.redirect || { name: 'projects-list' };
        await this.$router.replace(to);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 300px;
    background-color: var(--v-primary-base);
  }
</style>
